// Import Fonts:
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap');
// Bootstrap variable override:
@import "variables";
@import "bootstrap";
// Project's custom variables or mixin:
@import "functions";
@import "mixins";
@import "custom";
// plugins:
// layouts:
// core
@import "core/project-sitemap";
@import "layout";
@import "sections/header";
@import "sections/footer";
// pages:
@import "pages/home";
@import "pages/about";
// sections:
@import "sections/widget";
// Override anything at last:
@import "overrides";