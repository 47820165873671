header {
  background: transparent;
}
.nav-link {
  color: $white-color;
  &:hover {
    color: #b2d237;
  }
}
.nav-link.active {
  color: #b2d237;
}
