@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
/*** Font Family  ***/
$font-body: 'Rubik', sans-serif;
/*** Color Palette ***/
$primary-color: #4CB964; 
$secondary-color: #2E4057;
$white-color: #ffffff;
@mixin body-font { font-family: $font-body; font-size: 18px; font-weight:400; line-height: 27px; }
/*** Font-size as h1-h5 headings ***/
@mixin font-xl { @include fluid-type($min_width, $max_width, 44px, 68px);font-weight:700;}
@mixin font-lg { @include fluid-type($min_width, $max_width, 46px, 72px);line-height:0.8;color:$white-color;}
@mixin font-md { @include fluid-type($min_width, $max_width, 24px, 30px);line-height:1.5;font-weight:400;}
@mixin font-sm {font-size:24px;line-height:32px;}
@mixin font-xs {font-size:20px;line-height:1.5;}
@mixin font-xxs {font-size:14px;line-height:24px;}
@mixin border-radius($radius) { -webkit-border-radius: $radius; -moz-border-radius: $radius; -o-border-radius: $radius;	border-radius: $radius; }
@mixin opacity($opacity) { -webkit-opacity:$opacity; -moz-opacity:$opacity; -o-opacity:$opacity; -m-opacity:$opacity; opacity:$opacity; }
@mixin transition($seconds) { -webkit-transition: all $seconds ease; -moz-transition: all $seconds ease; -ms-transition: all $seconds ease; -o-transition: all $seconds ease; transition: all $seconds ease; }
@mixin trans-anim-none { -webkit-transition:none; -moz-transition:none; -o-transition:none; transition:none; }
@mixin space-none { margin:0; padding:0; }
@mixin app-none { -webkit-appearance: none; -moz-appearance: none; -o-appearance: none; -ms-appearance: none; appearance: none;}
@mixin point-none { -webkit-pointer-events:none; -moz-pointer-events:none; -o-pointer-events:none; -ms-pointer-events:none; pointer-events:none; }
/*** Background properties  ***/
@mixin bg-cmn { background-position:top center; background-repeat:no-repeat; background-size: cover; }
@mixin bg-parallax { background-attachment:fixed; @include bg-cmn; }
