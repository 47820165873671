.home1{
  background: url(../img/home-bg.jpg) no-repeat center center;
  flex: 0 0 auto;
  height: 980px;
  background-size: cover;
}

.laptop{ 
  background: linear-gradient(130deg, rgba(9, 1, 20, 0.8) 0%, rgba(17, 17, 17, 0.8) 100%);
  border-radius: 10px;
  box-shadow:rgba(0, 0, 0, 0.5) 0px 20px 40px 0px, rgba(255, 173, 241, 0.14) 1px 1px 1px 0px inset;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;  
  padding: 0px;
  transform: rotate(-2deg);
  width: 800px;
  position:relative;
  margin:70px auto;
}
.laptop-top{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  align-items: center;
}
.laptop-dots{
  display: flex;
  width: 42px;
  justify-content: space-between;
}
.laptop-dot{  
  background-color: rgba(68, 68, 68, 0.5);
  border-radius: 50%;
  flex: 0 0 auto;
  height: 10px;
  width: 10px;
}
.laptop-round{
  opacity: 0.4;
}
.laptop-screen{
  display:flex;
  flex:0 0 auto;
  gap:20px;
  padding:0px 20px 20px;
  width:100%;  
  height:316px;
}
.video-screen{
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 40px 0px, rgba(255, 173, 241, 0.14) 1px 1px 1px 0px inset;
  height: 100%;
  overflow: hidden;
  width: 531px;
}

.main-video{
  width: 100%;
  height: 100%;
  border-radius: 0px;
  display: block;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0);
}
.mobile-back{
  background-color: rgba(68, 68, 68, 0.3);
  border-radius: 6px;
  flex: 1 0 0px;
  height: 100%;
}
.laptop-bottom{
  background-color: rgba(68, 68, 68, 0.3);
  border-radius: 6px;
  padding: 10px 10px 10px 10px;
  flex-direction: row;
  column-gap: 10px;
  display: flex;
}
.laptop-bottom-block{background: linear-gradient(rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.6) 100%);
  opacity: 0.15;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0,0.1) 0px 0px 8px 0px;
  transform: none;
  transform-origin: 50% 50% 0px;
  width: 13.20%;
  height: 31px;
  flex: 0 0 auto;
}
.mobile-screen{
  background: linear-gradient(148deg, rgb(29, 12, 25) 0%, rgba(17, 17, 17, 0.8) 100%);
  border-radius: 22px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 40px 0px, rgba(255, 255, 255, 0.06) 1px 1px 1px 0px inset;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  overflow: visible;
  padding: 12px;
  transform: rotate(4deg);
  width: 230px;
  position: absolute;
  right:0;
  top: -20px;
}
.mobile-screen-bottom {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.mobile-screen-bottom-round1{
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width:32px;
  height:32px;
}
.mobile-screen-bottom-round2{
  background-color: rgba(255, 255, 255, 0.1);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin: 3px;
}

.client-logo{
  display: flex;
  justify-content: space-between;
  img{height:26px;}
}
.video-small{
  border-radius: 34px;
  overflow: hidden;
  video{
    width:100%;    
    display: block;
  }
}